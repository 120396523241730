import { onBeforeUnmount, onMounted } from 'vue'

export function useWindowHelpers () {
  function detectOutsideClick (component: any, callback: any) {
    if (!component) {
      return
    }

    const listener = (event: any): void => {
      if (event.target !== component.value && event.composedPath().includes(component.value)) {
        return
      }

      if (typeof callback === 'function') {
        callback()
      }
    }

    onMounted(() => {
      window.addEventListener('click', listener)
    })
    onBeforeUnmount(() => {
      window.removeEventListener('click', listener)
    })

    return { listener }
  }

  function scrollTo (elementId: string, margin = 0): void {
    const element = document.getElementById(elementId)

    if (element) {
      window.scrollTo(window.scrollX, element.offsetTop - margin)
    }
  }

  function scrollToSavedPosition (): void {
    if (import.meta.client && (window as any).savedPosition) {
      const { left, top } = (window as any).savedPosition

      window.scrollTo(left, top)

      delete (window as any).savedPosition
    }
  }

  return { detectOutsideClick, scrollTo, scrollToSavedPosition }
}
